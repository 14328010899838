export class CartUM {
  Products; // array
  Coupons; // array
  PaymentMethods; // array
  AmountResult; // object
  RewardPoint; // array
  OrderGifts; // object
  Activities; // object
  ShippingInfo; // array
  LimitShipping; // array
  RewardMoney; // object
}

export class CartProductUM {
  Id; // string
  OptionId; // string
  Name; // string
  OptionName; // string
  ImageUrl; // string
  Price; // number
  Quantity; // number
  Stock; // number
  SaleStatus; // string
  Gift; // object
  Type; // string
}

export class CartProductGiftUM {
  Id; // string
  Name; // string
  LimitPrice; // number
}

export class CartPaymentMethodUseUM {
  Used; // array
  CanUse; // array
}

export class CartPaymentMethodsUM {
  Code; // string
  Name; // string
  LimitPrice; // number
  Sort; // number
  DisplayOrder; // string
}

export class CartRewardPointUM {
  Total; // number
  CanUse; // number
  Used; // number
}

export class CartActivitiesUseUM {
  Used; // array
  CanUse; // array
}

export class CartActivitiesUM {
  Id; // string
  Name; // string
  DiscountAmount; // number
  Difference; // number
  LimitPrice; // string
  DiscountType; // string
  IsConform; // boolean
}

export class CartAmountResultUM {
  PayableAmount; // number 應付金額
  PointDiscount; // number 購物點數折扣金額
  ActivityDiscount; // number 活動折扣金額
  CouponDiscount; // number 優惠券折扣金額
  TotalShippingFee; // number 總運費
  UseRewardMoney; // number 使用福利金
  TotalDiscount; // number 總折扣金額
  PaidAmount; // number 實付金額
}
